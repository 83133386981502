<template>
  <div>
    <div class="AfterSales p32 p-t-32">
      <div style="margin-bottom: 10px;" class="tt df">
        <div class="suxian"></div>
        申请发票
      </div>
      <div class="customerName gr">{{ customerName }}</div>
      <el-row>
        <el-col :span="8" class="serchtab">
          <div class="li">
            <div class="serchName">随货同行单</div>
            <div class="ipt">
              <el-input
                clearable
                v-model="form.orderShipSn"
                placeholder="请输入随货同行单"
              ></el-input>
            </div></div
        ></el-col>
        <el-col :span="8" class="serchtab center">
          <div class="li">
            <div class="serchName">订单编号</div>
            <div class="ipt">
              <el-input
                clearable
                v-model="form.orderSn"
                placeholder="请输入订单编号"
              ></el-input>
            </div>
          </div>
        </el-col>
        <el-col :span="8" class="serchtab end">
          <div class="li">
            <div class="serchName">商品名称</div>
            <div class="ipt">
              <el-input
                clearable
                v-model="form.productName"
                placeholder="请输入商品名称"
              ></el-input>
            </div>
          </div>
        </el-col>
        <el-col :span="8" class="serchtab">
          <div class="li">
            <div class="serchName">厂家名称</div>
            <div class="ipt">
              <el-input
                clearable
                v-model="form.manufacturer"
                placeholder="请输入厂家名称"
              ></el-input>
            </div>
          </div>
        </el-col>
        <el-col :span="16" class="serchtab">
          <div class="serch">
            <el-button @click="serchList" type="success">搜索</el-button>
            <el-button @click="resetFn">重置</el-button>
          </div>
        </el-col>
      </el-row>

      <div class="tabel">
        <el-table
          :cell-style="cellStyle"
          :header-cell-style="{ background: '#F8F8F8' }"
          ref="singleTable"
          :data="tableData"
          height="600"
          highlight-current-row
          style="width: 100%"
        >
          <el-table-column align="center" type="index" label="序号" width="50">
          </el-table-column>

          <el-table-column
            align="center"
            property="createTime"
            label="商品"
            width="300"
          >
            <template slot-scope="scope">
              <Product-Card
                :showPrice="false"
                :isMyorder="true"
                :isMyInvoice="true"
                :rows="scope.row"
              >
              </Product-Card>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            show-overflow-tooltip
            property="orderShipSn"
            label="随货同行单"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            align="center"
            property="orderSn"
            show-overflow-tooltip
            label="订单编号"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            align="center"
            show-overflow-tooltip
            property="orderTime"
            label="下单时间"
            width="100"
          >
          </el-table-column>

          <el-table-column
            align="center"
            property="price"
            label="价格 (元)"
            show-overflow-tooltip
            width="auto"
          >
          </el-table-column>
          <el-table-column
            align="center"
            property="totalPrice"
            label="金额小计 (元)"
            show-overflow-tooltip
            width="auto"
          >
          </el-table-column>
          <el-table-column
            align="center"
            property="productQuantity"
            label="可开票数量"
            show-overflow-tooltip
            width="auto"
          >
          </el-table-column>

          <el-table-column align="center" label="操作" width="120">
            <template slot-scope="scope">
              <div @click="deletFn(scope.row)" class="cup info">移除</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="total > 0" class="pagenation">
        <!-- <Pagination :total="total" /> -->
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="form.pageNum"
          :page-size="10"
          layout="prev, pager, next, total,jumper"
          :total="total"
        ></el-pagination>
      </div>
      <div class="pagenation  next-step">
        <!-- <span>
            共 <span class="gr"> {{ total }} </span> 个</span
          > -->
        <el-button @click="nextStepFn" type="success">下一步</el-button>
      </div>
    </div>
    <el-dialog
      title="确认开票信息"
      :show-close="false"
      :visible.sync="dialogVisible"
      append-to-body
      width="480px"
    >
      <div class="card mt10">
        <div class="title">
          <div class="suxian"></div>
          请选择发票类型：
        </div>
        <div class="card-content">
          <el-radio
            v-for="(item, index) in resultInfoList.type"
            :key="item"
            v-model="billType"
            :label="item"
          >
            {{ item === 11 ? "A.增值税发票" : "B.普通发票" }}
          </el-radio>
        </div>
      </div>
      <div class="card">
        <div class="title">
          <div class="suxian"></div>
          开票信息
        </div>
        <div class="card-content">
          <el-descriptions :column="1">
            <!-- <el-descriptions-item label="申请客户">
              kooriookami</el-descriptions-item
            > -->
            <el-descriptions-item label="抬头类型">
              {{ resultInfoList.invoiceHeaderType }}</el-descriptions-item
            >
            <el-descriptions-item label="发票抬头">
              {{ resultInfoList.invoiceHeaderName }}</el-descriptions-item
            >
            <el-descriptions-item label="统一社会信用代码">
              {{ resultInfoList.socialCreditCode }}</el-descriptions-item
            >
            <el-descriptions-item label="开户银行名称">
              {{ resultInfoList.bankName }}</el-descriptions-item
            >
            <el-descriptions-item label="基本开户账号">
              {{ resultInfoList.bankNo }}</el-descriptions-item
            >
            <el-descriptions-item label="注册场所地址">
              {{ resultInfoList.registeredAddress }}</el-descriptions-item
            >
            <el-descriptions-item label="注册固定电话">
              {{ resultInfoList.registeredPhone }}</el-descriptions-item
            >
          </el-descriptions>
        </div>
      </div>
      <div class="card">
        <div class="title">
          <div class="suxian"></div>
          开票金额
        </div>
        <div class="card-content">
          <el-descriptions :column="1">
            <el-descriptions-item label="商品数量"
              >{{ resultInfoList.num }}个</el-descriptions-item
            >
            <el-descriptions-item label="开票总金额（元）">
              {{ resultInfoList.totalPrice }}</el-descriptions-item
            >
          </el-descriptions>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">上一步</el-button>
        <el-button type="primary" @click="addResultFn">提交申请</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/pagination.vue";
import InvoiceDetail from "./invoiceDetail.vue";
import {
  apiBillitemList,
  deleteSku,
  deleteAllSku,
  apiEditAddResult,
  editResultInfo,
} from "@/request/Orders/invoice";
import ProductCard from "@/components/custom-base/custom-product-card";
export default {
  components: {
    Pagination,
    InvoiceDetail,
    ProductCard,
  },
  data() {
    return {
      resultInfoList: {},
      billType: 11,
      dialogVisible: false,
      customerOptions: [],
      isAsc: "asc",
      total: 0,
      inputNo: "",
      tableData: [],
      form: {
        customerAddrId: undefined,
        manufacturer: "",
        orderShipSn: "",
        orderSn: "",
        pageNum: 1,
        pageSize: 10,
        productName: "",
      },
    };
  },
  props: {
    customerName: {
      default: () => {
        return "";
      },
    },
  },
  beforeDestroy() {},
  created() {},
  methods: {
    // 下一步
    async nextStepFn() {
      const { code, data } = await editResultInfo(this.form.billId);
      if (code === 200) {
        this.dialogVisible = true;
        this.resultInfoList = data;
      }
    },
    // 提交申请
    addResultFn() {
      this.$confirm("是否确认提交申请?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let data = {
          billType: this.billType,
          billId: this.form.billId,
        };
        apiEditAddResult(data).then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.dialogVisible = false;
            this.getList();
            this.$emit("closeFn");
          }
        });
      });
    },
    // 移除
    deletFn(item) {
      if (this.tableData.length === 1) {
        this.$message.warning("最少保留一个商品");
        return;
      }
      this.$confirm("是否确认移除该商品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteSku(item.applyItemId).then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.getList();
          }
        });
      });
    },

    cellStyle({ row, column, rowIndex, columnIndex }) {
      return "color: #333";
    },
    handleSizeChange(val) {},
    handleCurrentChange(val) {
      this.form.pageNum = val;
      this.getList();
    },
    //搜索
    serchList() {
      this.form.pageNum = 1;
      this.getList();
    },
    //重置
    resetFn() {
      this.form.manufacturer = "";
      this.form.orderShipSn = "";
      this.form.orderSn = "";
      this.form.productName = "";
      this.form.pageNum = 1;
      this.getList();
    },
    //获取列表
    getList(id) {
      if (id) {
        this.form.billId = id;
      }
      apiBillitemList(this.form).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.pageData?.rows || [];
          this.total = res.data.total;
        }
      });
    },

    goDetail(item) {
      if (!this.form.customerAddrId) {
        this.$message.warning("请先选择客户");
      } else {
        this.drawer = true;
        this.$nextTick(() => {
          this.$refs.InvoiceDetail.getList(this.form.customerAddrId);
        });
      }
    },

    activeList(index) {
      // 订单状态：-1全部 待审核-100 待回寄-110 待入库验收-120 待入账-130 已入账-140 审核驳回-150
      this.active = index;
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/base.less";
::v-deep {
  .el-dialog__body {
    padding: 0;
  }
  .el-select .el-input .el-select__caret {
    height: 32px;
    line-height: 32px;
  }
  .el-date-editor .el-range-separator {
    padding: 0;
    height: 32px;
    line-height: 32px;
  }
  .el-date-editor .el-range__icon {
    height: 32px;
  }

  .el-date-editor .el-range__close-icon {
    height: 32px;
  }
  .el-button {
    height: 32px;
    padding: 7px 18px;
  }
  .el-input__icon {
    height: 100%;
    width: 25px;
    text-align: center;
    transition: all 0.3s;
    line-height: 35px;
  }
  .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
}
.customerName {
  margin: 17px 0 25px 14px;
}

.card {
  .card-content {
    padding: 20px 40px;
  }
}

.suxian {
  margin-right: 8px;
}
.title {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 24px;
  background: #f7f8fa;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}
.add-customer {
  margin: 16px 0;
}
.tag-orange {
  padding: 4px 8px;
  font-size: 12px;
  line-height: 20px;
  background: #ffe7cf;
  border-radius: 3px;
  color: #ee9740;
}
.tag-info {
  padding: 4px 8px;
  font-size: 12px;
  line-height: 20px;
  background: #f6f7f9;
  border-radius: 3px;
  color: #7a8499;
}
.tag-blue {
  padding: 4px 8px;
  font-size: 12px;
  line-height: 20px;
  background: #e8f3ff;
  border-radius: 3px;
  color: #6aa1ff;
}
.tag-red {
  padding: 4px 8px;
  font-size: 12px;
  line-height: 20px;
  background: #fde5e5;
  border-radius: 3px;
  color: #f53f3f;
}
.tag-green {
  padding: 4px 8px;
  font-size: 12px;
  line-height: 20px;
  background: #e8ffea;
  border-radius: 3px;
  color: #7be188;
}
.none {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 600px;
}
.center {
  justify-content: center;
}
.info {
  color: #26c487;
  font-size: 14px;
}
.del {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.serch {
  flex: 1;
  text-align: right;
}
.serchtab {
  padding: 0 14px;
  margin-bottom: 16px;
  display: flex;

  .li {
    margin-bottom: 8px;
    height: 32px;
    line-height: 32px;
    display: flex;
    justify-content: space-between;
    .serchName {
      font-size: 12px;
      font-weight: 400;
      color: #333333;
      margin-right: 20px;
    }
    .ss {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    .ipt {
      width: 180px;
    }
  }
}
.end {
  justify-content: flex-end;
}
.center {
  justify-content: center;
}
.next-step {
  font-size: 12px;
}
.pagenation {
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.tt {
  font-size: 18px;
  font-family: 'medium';
  font-weight: 500;
  color: #333333;
}
.navlist {
  display: flex;
  .li {
    cursor: pointer;
    text-align: center;
    margin-right: 20px;
    padding: 0 20px;
    font-size: 16px;
    padding-bottom: 10px;
  }
  .active {
    font-size: 16px;
    font-family: 'medium';
    font-weight: 500;
    color: #26c487;
    color: #26c487;
    border-bottom: 4px solid #26c487;
  }
}
</style>
