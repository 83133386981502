<template>
  <div>
    <router-view></router-view>
    <div class="AfterSales p32 p-t-32" v-if="$route.path == '/user/myInvoice'">
      <div class="download-btn" @click="downLoadFun">导出</div>
      <div class="tt df">
        <div class="suxian"></div>
        发票列表
      </div>
      <div class="navlist">
        <div
          v-for="(item, index) in options"
          @click="activeList(item.value)"
          :class="form.status == item.value ? 'active li' : 'li'"
        >
          {{ item.label + "(" + item.num + ")" }}
        </div>
      </div>
      <div class="serchtab">
        <div class="li">
          <div class="serchName">发票申请编号</div>
          <div class="ipt">
            <el-input
              v-model="form.billSn"
              placeholder="请输入发票申请编号"
              @keyup.native.enter="serchList"
            ></el-input>
          </div>
        </div>
        <div class="li">
          <div class="serchName">申请时间</div>
          <div class="ipt">
            <el-date-picker
              type="daterange"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              v-model="inputDate"
              @change="changeDate"
            >
            </el-date-picker>
          </div>
        </div>

        <div class="serch">
          <el-button @click="serchList" type="success">搜索</el-button>
          <el-button @click="resetFn">重置</el-button>
        </div>
      </div>

      <div class="tabel">
        <el-table
          :cell-style="cellStyle"
          :header-cell-style="{ background: '#F8F8F8' }"
          ref="singleTable"
          :data="tableData"
          highlight-current-row
          style="width: 100%"
        >
          <el-table-column align="center" type="index" label="序号" width="50">
          </el-table-column>

          <el-table-column
            align="center"
            property="statu"
            label="发票状态"
            width="auto"
          >
            <template slot-scope="scope">
              <div class="df center aic tac">
                <div
                  :class="
                    scope.row.status === 10
                      ? 'tag-orange'
                      : scope.row.status === 11
                      ? 'tag-blue'
                      : scope.row.status === 13
                      ? 'tag-red'
                      : scope.row.status === 12
                      ? 'tag-green'
                      : scope.row.status === 14
                      ? 'tag-info'
                      : scope.row.status === 15
                      ? 'tag-green'
                      : ''
                  "
                >
                  {{
                    scope.row.status === 10
                      ? "待审核"
                      : scope.row.status === 11
                      ? "审核中"
                      : scope.row.status === 12
                      ? "审核通过"
                      : scope.row.status === 13
                      ? "审核失败"
                      : scope.row.status === 14
                      ? "已撤销"
                      : scope.row.status === 15
                      ? "已开票"
                      : ""
                  }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            property="createTime"
            label="申请时间"
            width="170"
          >
          </el-table-column>
          <el-table-column
            align="center"
            property="billSn"
            label="发票申请编号"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            align="center"
            property="customerName"
            label="申请客户"
            width="auto"
          >
          </el-table-column>
          <el-table-column
            align="center"
            property="type"
            label="发票类型"
            width="auto"
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.billType === 11 ? "增值税发票" : "普通发票" }}
              </div>
            </template>
          </el-table-column>

          <el-table-column align="center" label="操作" width="160">
            <template slot-scope="scope">
              <div class="flex-center">
                <div @click="goDetail(scope.row)" class="cup info">详情</div>
                <div
                  v-if="scope.row.status === 10"
                  @click="canselApply(scope.row)"
                  class="cup info"
                >
                  撤销申请
                </div>
                <div
                  v-if="scope.row.status === 13 || scope.row.status === 14"
                  @click="goDetail(scope.row, 2)"
                  class="cup info"
                >
                  编辑
                </div>
                <div
                  v-if="scope.row.status === 15 && scope.row.billUrl"
                  @click="downLoad(scope.row)"
                  class="cup info"
                >
                  下载
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="total > 0" class="pagenation">
        <!-- <Pagination :total="total" /> -->
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="form.pageNum"
          :page-size="10"
          layout="prev, pager, next, total,jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>

    <el-drawer
      @close="closeFn"
      :visible.sync="drawer"
      size="80%"
      title="发票详情"
    >
      <InvoiceDetail
        @closeFn="closeFn"
        @getList="getList"
        @canselApply="canselApply"
        ref="InvoiceDetail"
      ></InvoiceDetail>
    </el-drawer>
  </div>
</template>

<script>
import InvoiceDetail from "./invoiceDetail.vue";
import {
  apiGetList,
  apiGetstatistics,
  apiCancel,
  apiBillListDownload
} from "@/request/Orders/invoice";
export default {
  components: {
    InvoiceDetail,
  },
  data() {
    return {
      drawer: false,
      isAsc: "asc",
      page: 1,
      total: 0,
      OptionStatus: -1,
      options: [
        {
          value: "10",
          label: "待审核",
          num: 0,
        },
        {
          value: "14",
          label: "已撤销",
          num: 0,
        },
        {
          value: "11",
          label: "审核中",
          num: 0,
        },
        {
          value: "13",
          label: "审核失败",
          num: 0,
        },
        {
          value: "15",
          label: "已开票",
          num: 0,
        },
      ],
      value: "",
      //下单时间
      inputDate: [],
      tableData: [],
      form: {
        billSn: "",
        // customerAddrId: 0,
        endTime: "",
        isAsc: "asc",
        pageNum: 1,
        pageSize: 10,
        sort: 0,
        startTime: "",
        status: 15,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    downLoad(item) {
      this.download(`${item.billUrl}`, {}, `电子发票${item.billSn}.PDF`);
    },
    // 撤销申请
    canselApply(item) {
      this.$confirm("是否确认撤销申请?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        apiCancel({ billId: item.billId }).then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.getList();
          }
        });
      });
    },
    async Getstatistics() {
      const { code, data } = await apiGetstatistics();
      if (code === 200) {
        this.options[0].num = data.toApprovalNum;
        this.options[1].num = data.cancelNum;
        this.options[2].num = data.underApprovalNum;
        this.options[3].num = data.approvalFailNum;
        this.options[4].num = data.invoicedNum;
      }
    },
    // 排序
    changeTableSort(column) {
      // this.fieldName = column.prop;
      var sortingType = column.order;
      sortingType == "ascending" ? (this.isAsc = "asc") : (this.isAsc = "desc");
      this.getList();
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return "color: #333";
    },
    handleSizeChange(val) {},
    handleCurrentChange(val) {
      this.form.pageNum = val;
      this.getList();
    },
    //搜索
    serchList() {
      this.form.pageNum = 1;
      this.getList();
    },
    //获取列表
    async getList() {
      const { code, data } = await apiGetList(this.form);
      if (code === 200) {
        this.tableData = data.rows;
        this.total = data.total;
        this.Getstatistics();
      }
    },
    changeDate(e) {
      if (e) {
        this.form.startTime = e[0];
        this.form.endTime = e[1];
      } else {
        this.form.startTime = undefined;
        this.form.endTime = undefined;
      }
      this.serchList();
    },

    //重置
    resetFn() {
      this.inputDate = [];
      this.form.startTime = "";
      this.form.endTime = "";
      this.form.billSn = "";
      this.form.pageNum = 1;
      this.getList();
    },
    goDetail(item, type) {
      this.drawer = true;
      this.$nextTick(() => {
        this.$refs.InvoiceDetail.getList(item.billId);
        if (type === 2) {
          this.$refs.InvoiceDetail.editFn();
        }
      });
      /* this.$router.push({
        name: "InvoiceDetail",
      }); */
    },
    closeFn() {
      this.drawer = false;
      // this.$refs.InvoiceDetail.resetFn();
    },
    activeList(index) {
      this.form.status = index;
      this.getList();
    },
    
    downLoadFun() {
      this.download('/pay-v2/mall/bill/export',{ 
        ...this.form
      },  `发票信息.xlsx`);
    }
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/base.less";
::v-deep {
  .el-select .el-input .el-select__caret {
    height: 32px;
    line-height: 32px;
  }
  .el-date-editor .el-range-separator {
    padding: 0;
    height: 32px;
    line-height: 32px;
  }
  .el-date-editor .el-range__icon {
    height: 32px;
  }

  .el-date-editor .el-range__close-icon {
    height: 32px;
  }
  .el-button {
    height: 32px;
    padding: 7px 18px;
  }
  .el-input__icon {
    height: 100%;
    width: 25px;
    text-align: center;
    transition: all 0.3s;
    line-height: 35px;
  }
  .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
}
.flex-center {
  display: flex;
  justify-content: center;
  div {
    margin-right: 12px;
  }
}
.tag-orange {
  padding: 4px 8px;
  font-size: 12px;
  line-height: 20px;
  background: #ffe7cf;
  border-radius: 3px;
  color: #ee9740;
}
.tag-info {
  padding: 4px 8px;
  font-size: 12px;
  line-height: 20px;
  background: #f6f7f9;
  border-radius: 3px;
  color: #7a8499;
}
.tag-blue {
  padding: 4px 8px;
  font-size: 12px;
  line-height: 20px;
  background: #e8f3ff;
  border-radius: 3px;
  color: #6aa1ff;
}
.tag-red {
  padding: 4px 8px;
  font-size: 12px;
  line-height: 20px;
  background: #fde5e5;
  border-radius: 3px;
  color: #f53f3f;
}
.tag-green {
  padding: 4px 8px;
  font-size: 12px;
  line-height: 20px;
  background: #e8ffea;
  border-radius: 3px;
  color: #7be188;
}
.none {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 600px;
}
.center {
  justify-content: center;
}
.info {
  color: #26c487;
  font-size: 14px;
}
.del {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.serch {
  flex: 1;
  text-align: right;
}
.serchtab {
  margin: 30px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .li {
    margin-right: 70px;
    height: 32px;
    line-height: 32px;
    display: flex;
    justify-content: space-between;
    .serchName {
      font-size: 12px;
      font-family: 'regular';
      font-weight: 400;
      color: #333333;
      margin-right: 20px;
    }
    .ss {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    .ipt {
      flex: 1;
    }
  }
}
.pagenation {
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-end;
}
.tt {
  font-size: 18px;
  font-family: 'medium';
  font-weight: 500;
  color: #333333;
}
.navlist {
  display: flex;
  .li {
    cursor: pointer;
    text-align: center;
    margin-right: 20px;
    padding: 0 20px;
    font-size: 16px;
    padding-bottom: 10px;
  }
  .active {
    font-size: 16px;
    font-family: 'medium';
    font-weight: 500;
    color: #26c487;
    color: #26c487;
    border-bottom: 4px solid #26c487;
  }
}
.AfterSales {
  position: relative;
}
.download-btn {
  width: 64px;
  height: 32px;
  background: #26C487;
  color: #ffffff;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 30px;
}
</style>
