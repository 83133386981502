<template>
  <div>
    <div v-show="!showEdit" class="body">
      <div class="top-box">
        <img class="first" src="../../../assets/images/invoice/top-front.png" alt="">
        <div class="content">
          <span class="content-price">{{ detailList.totalAmt }}</span>
          <span style="margin-right: 24px">开票总金额 (元)</span>
          <span>商品品类数：{{ total }}</span>
        </div>
        <img src="../../../assets/images/invoice/top-back.png" alt="">
      </div>

      <div class="card">
        <el-descriptions :column="3" class="userinfo-content">
          <el-descriptions-item>
            <template slot="label">
              <img src="../../../assets/images/invoice/user-green.png" />
              抬头类型
            </template>
            {{ detailList.headerType }}</el-descriptions-item
          >
          <el-descriptions-item>
            <template slot="label">
              <img
                src="../../../assets/images/invoice/fapiaotaitou-green.png"
              />
              发票抬头
            </template>
            {{ detailList.billHeader }}</el-descriptions-item
          >
          <el-descriptions-item>
            <template slot="label">
              <img src="../../../assets/images/invoice/bankIcon-green.png" />
              开户银行名称
            </template>
            {{ detailList.bankName }}</el-descriptions-item
          >
          <el-descriptions-item>
            <template slot="label">
              <img src="../../../assets/images/invoice/jiben-green.png" />
              基本开户账号
            </template>
            {{ detailList.bankNun }}</el-descriptions-item
          >
          <el-descriptions-item>
            <template slot="label">
              <img src="../../../assets/images/invoice/tongyi-green.png" />
              统一社会信用代码
            </template>
            {{ detailList.unifiedCreditCode }}</el-descriptions-item
          >
          <el-descriptions-item>
            <template slot="label">
              <img src="../../../assets/images/invoice/ipIcon.png" />
              注册场所地址
            </template>
            <el-tooltip
              class="item"
              effect="dark"
              :content="detailList.address"
              placement="top-start"
            >
              <span class="ellipsis-one"> {{ detailList.address }}</span>
            </el-tooltip>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <img src="../../../assets/images/invoice/phoneIcon-green.png" />
              注册固定电话
            </template>
            {{ detailList.phone }}</el-descriptions-item
          >
        </el-descriptions>
      </div>
      <div class="card">
        <div class="title">
          <div class="suxian"></div>
          商品列表
        </div>
        <el-row style="padding:0 14px">
          <el-col :span="5" class="serchtab">
            <div class="li">
              <div class="serchName">关联出库单</div>
              <div class="ipt">
                <el-input
                  clearable
                  v-model="form.orderShipSn"
                  placeholder="请输入"
                ></el-input>
              </div></div
          ></el-col>
          <el-col :span="5" class="serchtab center">
            <div class="li">
              <div class="serchName">订单编号</div>
              <div class="ipt">
                <el-input
                  clearable
                  v-model="form.orderSn"
                  placeholder="请输入"
                ></el-input>
              </div>
            </div>
          </el-col>
          <el-col :span="5" class="serchtab end">
            <div class="li">
              <div class="serchName">商品名称</div>
              <div class="ipt">
                <el-input
                  clearable
                  v-model="form.productName"
                  placeholder="请输入"
                ></el-input>
              </div>
            </div>
          </el-col>
          <el-col :span="5" class="serchtab">
            <div class="li">
              <div class="serchName">厂家名称</div>
              <div class="ipt">
                <el-input
                  clearable
                  v-model="form.manufacturer"
                  placeholder="请输入"
                ></el-input>
              </div>
            </div>
          </el-col>
          <el-col :span="4" class="serchtab">
            <div class="serch">
              <el-button @click="serchList" type="success">搜索</el-button>
              <el-button @click="resetFn">重置</el-button>
            </div>
          </el-col>
        </el-row>

          <el-table
            :data="detailList.productResDTOS.rows"
            tooltip-effect="dark"
            :header-cell-style="{ background: '#F7F8FA ', fontSize: '12px' }"
            style="width: 100%"
            height="440"
          >
            <el-table-column
              label="序号"
              align="center"
              type="index"
              width="100"
            >
            </el-table-column>
            <el-table-column
              label="商品"
              show-overflow-tooltip
              align="center"
              width="350"
            >
              <template slot-scope="scope">
                <Product-Card
                  :isMyorder="true"
                  :showPrice="false"
                  :rows="scope.row"
                >
                </Product-Card>
              </template>
            </el-table-column>
            <el-table-column
              prop="orderShipSn"
              label="关联出库单"
              show-overflow-tooltip
              align="center"
              width="auto"
            >
            </el-table-column>
            <el-table-column
              prop="receiptsType"
              label="单据类型"
              show-overflow-tooltip
              width="auto"
              align="center"
            >
              <template slot-scope="scope">
                <div>{{ getLabelByValue(scope.row.receiptsType, billTypeList) }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="orderSn"
              label="订单编号"
              show-overflow-tooltip
              width="auto"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="invoicedQuantity"
              label="已开票数"
              width="auto"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="salesPrice"
              label="开票单价(元)"
              width="auto"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="totalPrice"
              label="金额小计(元)"
              width="auto"
              align="center"
            >
            </el-table-column>
          </el-table>
          <div v-if="total > 0" class="pagenation">
            <!-- <Pagination :total="total" /> -->
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="form.pageNum"
              :page-size="10"
              layout="prev, pager, next, total,jumper"
              :total="total"
            ></el-pagination>
          </div>
      </div>
      <!-- <div class="card">
        <div class="title">
          <div class="suxian"></div>
          审批记录
        </div>
        <el-timeline class="mt30">
          <el-timeline-item
            v-for="(item, index) in detailList.approvalResDTOS"
            :key="index"
            :timestamp="item.time"
            color="#26C487"
          >
            <div class="timeline-statu">
              <span style="margin-right: 30px">
                {{ item.reason }}
              </span>
            </div>
          </el-timeline-item>
        </el-timeline>
      </div> -->
      <div class="bottom">
        <el-button
          v-if="detailList.status === 13 || detailList.status === 14"
          type="success"
          @click="editFn"
          >重新编辑</el-button
        >
        <el-button
          v-if="detailList.status === 10"
          @click="$emit('canselApply', detailList)"
          type="success"
          >撤销申请</el-button
        >
      </div>
    </div>

    <edit
      v-show="showEdit"
      :customerName="detailList.customerName"
      @closeFn="$emit('closeFn')"
      ref="refEdit"
    ></edit>
  </div>
</template>

<script>
import ProductCard from "@/components/custom-base/custom-product-card";
import edit from "./edit.vue";
import { apiBillListInfo } from "@/request/Orders/invoice";
import { getLabelByValue } from '../../../utils/tool';

export default {
  components: {
    ProductCard,
    edit,
  },
  data() {
    return {
      getLabelByValue,
      showEdit: false,
      total: 0,
      ProductList: [{ name: 1 }],
      inputNo: "",
      billId: undefined,
      form: {
        billId: 0,
        isAsc: "asc",
        manufacturer: "",
        orderShipSn: "",
        orderSn: "",
        pageNum: 1,
        pageSize: 10,
        productName: "",
      },
      detailList: {
        productResDTOS: {
          rows: [],
        },
      },

      billTypeList: [
        {label: '销售单', value: 216},
        {label: '调价单', value: 217},
        {label: '销退单', value: 218},
        {label: '折让单', value: 260},
      ]
    };
  },
  methods: {
    closeFn() {
      this.$refs.refEdit.resetFn();
      this.$emit("closeFn");
    },
    editFn() {
      this.showEdit = true;
      this.$refs.refEdit.getList(this.form.billId);
    },
    //获取列表
    getList(id) {
      if (id) {
        this.form.billId = id;
      }
      apiBillListInfo(this.form).then((res) => {
        if (res.code === 200) {
          this.detailList = res.data;
          this.total = res.data.productResDTOS.total;
        }
      });
    },
    serchList() {
      this.form.pageNum = 1;
      this.getList();
    },
    resetFn() {
      this.form.manufacturer = "";
      this.form.orderShipSn = "";
      this.form.orderShipSn = "";
      this.form.orderSn = "";
      this.form.productName = "";
      this.form.pageNum = 1;
      this.showEdit = false;
      this.getList();
    },
    handleSizeChange(val) {},
    handleCurrentChange(val) {
      this.form.pageNum = val;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../assets/base.less";
::v-deep {
  .el-timeline-item__node--normal {
    left: 1px;
    width: 8px;
    height: 8px;
  }
}
.ellipsis-one {
  max-width: 300px;
}
.timeline-statu {
  font-size: 12px;
}
.pagenation {
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-end;
}
.serch {
  flex: 1;
  text-align: right;
}
.center {
  justify-content: center;
}
.end {
  justify-content: flex-end;
}

.serchtab {
  padding: 10px 0 10px 20px;
  display: flex;

  .li {
    margin-bottom: 8px;
    height: 32px;
    line-height: 32px;
    display: flex;
    justify-content: space-between;
    .serchName {
      font-size: 12px;
      font-weight: 400;
      color: #333333;
      margin-right: 8px;
    }
    .ss {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
    .ipt {
      width: 130px;
    }
  }
}

.body {
  padding: 30px 20px;
}
.suxian {
  margin-right: 8px;
}
.title {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 24px;
  background: #f7f8fa;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}
.card {
  .userinfo-content {
    padding-left: 38px;
    margin: 20px 0;
  }
  img {
    margin-right: 5px;
  }
}
.bottom {
  display: flex;
  justify-content: flex-end;
}

.top-box {
  width: 100%;
  height: 72px;
  background: linear-gradient(325deg, #26C487 0%, #56DCA9 100%);
  border-radius: 2px;
  display: flex;
  align-items: center;
  color: #ffffff;
  padding-left: 20px;
  .first {
    width: 32px;
    height: 32px;
    margin-right: 16px;
  }
  .content {
    flex: 1;
  }
  .content-price {
    font-size: 24px;
    font-weight: bold;
    margin-right: 8px;
  }
}
</style>
